import React from "react";
import { Link, Outlet } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useState, useEffect } from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { NavLink, useNavigate } from "react-router-dom";

const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Admin() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem('admin_token');
    localStorage.removeItem('access_token');
    navigate('/');
  };

  const [adminName, setAdminName] = useState('');
  useEffect(() => {
    const admin_token = localStorage.getItem('admin_token');
    if (!admin_token) {
      console.log('No admin token')
      navigate('/');
    }
    fetch(`/api/admin/name`,
      {
        method: 'get',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Authorization': 'Bearer ' + admin_token,
        },
      }
    )
    .then(res => {
      if (res.status === 401) {
        alert('セッションが切れました。');
        navigate('/');
      } else if (res.status === 500) {
        navigate('/500');
      } else if (res.status === 404) {
        navigate('/404');
      }
      return res.json()
    })
    .then(res => {
      console.log(res.message);
      setAdminName(res.adminName);
    })
    .catch(error => {
      alert('Error:', error);
      navigate('/');
    });
  }, [navigate])

  return(
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ backgroundImage: "linear-gradient(to right, #abcfce, #d7b27a)" }} open={open}>
            <Toolbar>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: 'none' }), width: '2%' }}
              >
                  <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textDecoration: 'none', color: 'white' }}>
                <NavLink to="/MyPageAdmin" style={{ textDecoration: 'none', color: 'white' }}>
                  THECOACH LMS -ADMINISTER-
                </NavLink>
              </Typography>
              <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                  {`ようこそ！${adminName}様`}
                </Typography>
                <div style={{ width: 20 }} />
              <Button 
                onClick={handleLogOut}
                color="inherit"
                variant="outlined"
                sx={{ borderRadius: '20px' }}
              >
                LOGOUT
              </Button>
            </Toolbar>
        </AppBar>
        <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  whiteSpace: 'nowrap',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
          <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem key={'My Page Admin'} disablePadding>
                <ListItemButton
                  component={Link}
                  to="/MyPageAdmin"
                >
                    <PersonIcon/>
                    <ListItemText primary={'マイページ'} />
                </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key={'User List'} disablePadding>
              <ListItemButton
                component={Link}
                to="/UserList"
              >
                <ListAltIcon/>
                <ListItemText primary={'受講者一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Lecture List'} disablePadding>
              <ListItemButton
                component={Link}
                to="/LectureList"
              >
                <ListAltIcon/>
                <ListItemText primary={'講座一覧'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Outlet/>
        </Main>
      </Box>
    </div>
  );
}