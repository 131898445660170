import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login"
import Admin from "./Admin";
import User from "./User";
import Error404 from "./404";
import Error500 from "./500";
import MyPageAdmin from "./components/MyPageAdmin";
import MyPage from "./components/MyPage";
import VideoList from "./components/VideoList";
import UserList from "./components/UserList"
import LectureList from "./components/LectureList"
import LoginLogoutList from "./components/LoginLogoutList"
import { useState, useEffect } from 'react'

export default function Router(){
    const [lectureData, setLectureData]= useState([]);
    useEffect(() =>{
        fetch(`/api/login/lectures`)
        .then(res => res.json())
        .then(content => {
            console.log(content.message);
            setLectureData(content.lectures)
        })
        .catch(() =>{
            alert("Can't get data from db properly.")
        });
    }, []); 

    return(
        <BrowserRouter>
            <div className="Router">
                <Routes>
                    <Route exact path="/" element={<Login/>}/>
                    <Route path="/404" element={<Error404/>}/>
                    <Route path="/500" element={<Error500/>}/>
                    <Route element={<Admin/>}>
                        <Route key="MyPageAdmin" path="MyPageAdmin" element={<MyPageAdmin/>}/>
                        <Route key="UserList" path="UserList" element={<UserList/>}/>
                        <Route key="LectureList" path="LectureList" element={<LectureList/>}/>
                    </Route>
                    <Route element={<User/>}>
                        <Route key="MyPage" path="/MyPage" element={<MyPage/>}/>
                        {lectureData.map((row) => (
                            <Route key={row.id} path={"/lecture/"+row.id} element={<VideoList id={row.id} lectureName={row.lectureName}/>} />
                        ))}
                        <Route key="LoginLogoutList" path="/LoginLogoutList" element={<LoginLogoutList/>}/>
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    );
}