import React from "react";
import { Link, Outlet } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import TopicIcon from '@mui/icons-material/Topic';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { NavLink, useNavigate } from "react-router-dom";

const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function User() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
  setOpen(true);
  };

  const handleDrawerClose = () => {
  setOpen(false);
  };

  const [lectureData, setLectureData]= useState([]);
  const [userName, setUserName]= useState('');
  useEffect(() =>{
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token')
      navigate('/');
    }
    fetch(`/api/user/lectures`, 
      {
        method: 'get', 
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      }
    )
    .then(res => {
      if (res.status === 401) {
        alert('セッションが切れました。');
        navigate('/');
      } else if (res.status === 500) {
        navigate('/500');
      } else if (res.status === 404) {
        navigate('/404');
      }
      return res.json()
    })
    .then(res => {
      console.log(res.message);
      setLectureData(res.lectures);
      setUserName(res.userName);
    })
    .catch(error => {
      alert('Error:', error);
      navigate('/');
    });
  }, [navigate]); 

  const handleLogOut = async () => {
    try{
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token');
        navigate('/');
      }
      const time = new Date();
      const japanTime = new Date(time.getTime() + (9 * 60 * 60 * 1000));
      const logOutTime = japanTime.toISOString();
      console.log('User Logout at :' + logOutTime);
      const data = new FormData();
      data.append('logout_time', logOutTime);
      fetch(`/api/user/logout`, 
        {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Authorization': 'Bearer ' + token,
          },
          body: data,
        }
      )
      .then(res => res.json())
      .then(res => {
        console.log(res.message);
        if (res.status === 'lou1') {
          console.log(res.message);
        } else if (res.status === 'lou3') {
          console.log(res.message);
        } else {
          console.log(res.message)
        }
        localStorage.removeItem('token');
        localStorage.removeItem('access_token');
        navigate('/');
      })
      .catch(error => {
        alert('Error:', error);
        navigate('/');
      });
    } catch (error) {
      console.log('Error:', error);
      navigate('/');
    }
  }

  return(
      <div className="App">
        <Box sx={{ flexGrow: 1 }}>
          <CssBaseline />
          <AppBar position="fixed" sx={{ backgroundImage: "linear-gradient(to right, #abcfce, #d7b27a)" }} open={open}>
              <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                  <NavLink to="/MyPage" style={{ textDecoration: 'none', color: 'white' }}>
                    THECOACH LMS
                  </NavLink>
                </Typography>
                <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                  {`ようこそ！${userName}様`}
                </Typography>
                <div style={{ width: 20 }} />
                <Button 
                  onClick={handleLogOut}
                  color="inherit"
                  variant="outlined"
                  sx={{ borderRadius: '20px' }}
                >
                  LOGOUT
                </Button>
              </Toolbar>
          </AppBar>
          <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
          >
              <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                  <ListItem key={'My Page'} disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/MyPage"
                      >
                          <PersonIcon/>
                          <ListItemText primary={'マイページ'}/>
                      </ListItemButton>
                  </ListItem>
                  <ListItem key={'Login-Logout-List'} disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/LoginLogoutList"
                      >
                          <AccessTimeIcon/>
                          <ListItemText primary={'ログイン/ログアウト履歴'}/>
                      </ListItemButton>
                  </ListItem>
              </List>
              <Divider />
              <List>
                { lectureData? lectureData.map((row) => (
                  <ListItem key={row.id} disablePadding>
                    <ListItemButton
                      component={Link}
                      to={"/lecture/"+row.id}
                    >
                        <TopicIcon />
                        <ListItemText primary={row.lectureName} />
                    </ListItemButton>
                  </ListItem>
                )) : <div></div>}
              </List>
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            <Outlet/>
          </Main>
        </Box>
      </div>
    );
}