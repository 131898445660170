import React from "react";
import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const LoginDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        maxWidth: '500px', 
        maxHeight: '500px',
        width: '100%',
        height: '100%', 
        borderRadius: '20px',    
    },
}));

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
        },
    },
    '& .MuiFormHelperText-root': {
        color: 'red',
    },
});

export default function Login(){
    const [openUser, setOpenUser] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
    });
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [formDisabled, setFormDisabled] = useState(false); 
    const [countdown, setCountdown] = useState(0);
    const [openAdmin, setOpenAdmin] = useState(false);
    const [adminEmail, setAdminEmail] = useState("");
    const [adminEmailError, setAdminEmailError] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [adminValues, setAdminValues] = useState({
        password: '',
        showPassword: false,
    });
    const [adminLoginAttempts, setAdminLoginAttempts] = useState(0);
    const [adminFormDisabled, setAdminFormDisabled] = useState(false);
    const [adminCountdown, setAdminCountdown] = useState(0); 
    const maxLoginAttempts = 5; 
    const remainingAttempts = maxLoginAttempts - loginAttempts;
    let disabledMessage = '';
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token){
            const time = new Date();
            const japanTime = new Date(time.getTime() + (9 * 60 * 60 * 1000));
            const logOutTime = japanTime.toISOString();
            const data = new FormData();
            data.append('logout_time', logOutTime);
            fetch(`/api/user/logout`, 
                {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                    body: data,
                }
            )
            .then(res => res.json())
            .then(res => {
                console.log(res.message);
            });
        }
        localStorage.removeItem('token');
        localStorage.removeItem('admin_token');
        localStorage.removeItem('access_token');
    }, []);

    if (loginAttempts >= 5) {
        disabledMessage = `次にアクセス可能な時間: ${Math.floor(countdown / 60)}分 ${countdown % 60}秒後`;
    }

    useEffect(() => {
        if (loginAttempts >= 5) {
            setFormDisabled(true); 
            const timer = setTimeout(() => {
                setLoginAttempts(0); //試行回数をリセット
                setFormDisabled(false); //フォームを再開可能にする
                setCountdown(0); //カウントダウンをリセット
            }, 1 * 60 * 1000); //1分後にフォームを再開可能にする
            setCountdown(1 * 60); //カウントダウンを設定（秒単位）
            return () => clearTimeout(timer);
        }
    }, [loginAttempts]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const adminMaxLoginAttempts = 5; 
    const adminRemainingAttempts = adminMaxLoginAttempts - adminLoginAttempts;
    let adminDisabledMessage = '';

    if (adminLoginAttempts >= 5) {
        adminDisabledMessage = `次にアクセス可能な時間: ${Math.floor(adminCountdown / 60)}分 ${adminCountdown % 60}秒後`;
    }

    useEffect(() => {
        if (adminLoginAttempts >= 5) {
            setAdminFormDisabled(true); 
            const timer = setTimeout(() => {
                setAdminLoginAttempts(0); //試行回数をリセット
                setAdminFormDisabled(false); //フォームを再開可能にする
                setAdminCountdown(0); //カウントダウンをリセット
            }, 1 * 60 * 1000); //1分後にフォームを再開可能にする
            setAdminCountdown(1 * 60); //カウントダウンを設定（秒単位）
            return () => clearTimeout(timer);
        }
    }, [adminLoginAttempts]);

    useEffect(() => {
        if (adminCountdown > 0) {
            const timer = setTimeout(() => {
                setAdminCountdown((prev) => prev - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [adminCountdown]);

    const handleOpenUser = () => {
        setOpenUser(true);
    }

    const handleCloseUser = () => {
        setOpenUser(false);
    }

    const handleCloseAdmin = () => {
        setOpenAdmin(false);
    }

    const handleLoginUser = async () => {
        if(email !== "" && values.password !== "") {
            const data = new FormData();
            data.append('login_email', email);
            data.append('login_password', values.password);
            fetch(`/api/login/user`, 
                {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' ,
                    },
                    body: data,
                }
            )
            .then(res => res.json())
            .then(res => {
                if (res.status === 'lu1') {
                    console.log(res.message);
                    localStorage.setItem('token', res.token);
                    navigate('/MyPage');
                } else if (res.status === 'lu2') {
                    console.log(res.message);
                    setPasswordError("パスワードが間違っています");
                    setLoginAttempts((prev) => prev + 1);
                } else if (res.status === 'lu3') {
                    console.log(res.message);
                    setPasswordError("パスワードが間違っています");
                    setLoginAttempts((prev) => prev + 1);
                } else {
                    console.log(res.message);
                    setPasswordError("パスワードが間違っています");
                    setLoginAttempts((prev) => prev + 1);
                }
            })
        } else if (values.password !== "") {
            setEmailError("メールアドレスを入力してください");
        } else if (email !== "") {
            setPasswordError("パスワードを入力してください");
        } else {
            setEmailError("メールアドレスを入力してください");
            setPasswordError("パスワードを入力してください");
        }
    };

    const handleLoginAdmin = async () => {
        if(adminEmail !== "" && adminValues.password !== "") {
            const data = new FormData();
            data.append('login_admin_email', adminEmail);
            data.append('login_admin_password', adminValues.password);
            fetch(`/api/login/admin`, 
                {
                    method: 'post',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ',
                    },
                    body: data,
                }
            )
            .then(res => res.json())
            .then(res => {
                if (res.status === 'la1') {
                    console.log(res.message);
                    localStorage.setItem('admin_token', res.admin_token);
                    navigate('/MyPageAdmin');
                } else if (res.status === 'la2') {
                    console.log(res.message);
                    setAdminPasswordError("パスワードが間違っています");
                    setAdminLoginAttempts((prev) => prev + 1);
                } else if (res.status === 'la3') {
                    console.log(res.message);
                    setAdminPasswordError("パスワードが間違っています");
                    setAdminLoginAttempts((prev) => prev + 1);
                } else {
                    console.log(res.message);
                    setAdminPasswordError("パスワードが間違っています");
                    setAdminLoginAttempts((prev) => prev + 1);
                }
            })
        } else if (adminValues.password !== "") {
            setAdminEmailError("メールアドレスを入力してください");
        } else if (adminEmail !== "") {
            setAdminPasswordError("パスワードを入力してください");
        } else {
            setAdminEmailError("メールアドレスを入力してください");
            setAdminPasswordError("パスワードを入力してください");
        }
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleClickShowAdminPassword = () => {
        setAdminValues({ ...adminValues, showPassword: !adminValues.showPassword });
    };

    const handleClicktoAdmin = () => {
        setOpenUser(false);
        setOpenAdmin(true);
    };

    const handleClicktoUser = () => {
        setOpenUser(true);
        setOpenAdmin(false);
    }

    return(
        <Grid 
            container 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
            }} 
        > 
            <Box
                sx={{
                    backgroundImage: 'linear-gradient(to right, #abcfce, #d7b27a)',
                    margin: '50px 0px', 
                    padding: '80px 0px',
                    textAlign: 'center',
                    width: '100%', 
                }}
            >
                <motion.div
                    initial={{ x: "-100vw" }}
                    animate={{ x: 0 }}
                    transition={{ type: "spring", stiffness: 120, duration: 1 }}
                >
                    <Typography 
                        component="div"
                        sx={{ 
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '150px', 
                            textDecoration: 'underline',
                        }} 
                    >
                        THECOACH LMS
                    </Typography>
                </motion.div>
                <Typography 
                    component="div"
                    sx={{ 
                        color: 'white',
                        fontSize: '30px', 
                    }} 
                >
                    受講者専用ログインページ
                </Typography>
            </Box>
            <div style={{ margin: '10px' }}></div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
            >
                <Button 
                    variant="contained"
                    onClick={handleOpenUser}
                    disabled={formDisabled}
                    sx={{
                        color: 'transparent',
                        backgroundImage: 'linear-gradient(to right, #abcfce, #d7b27a)',
                        backgroundClip: 'text',
                        border: '5px solid #c1c1a4',
                        borderRadius: '48px',
                        width: '300px', 
                        height: '75px',
                        fontSize: '20px', 
                        '&:hover': {
                            backgroundImage: "linear-gradient(to right, #abcfce, #d7b27a)",
                            color: 'white',
                            backgroundClip: 'border-box',
                            border: '5px solid white',
                        }
                    }}
                >
                    ログインする
                </Button>
            </motion.div>
            <LoginDialog
                open={openAdmin}
                aria-labelledby="admin-login-dialog"
            >
                <DialogTitle 
                    sx={{ 
                        m: 0, p: 2, 
                        color: 'transparent',
                        backgroundImage: 'linear-gradient(to right, #abcfce, #d7b27a)',
                        backgroundClip: 'text',
                    }} 
                    id="admin-login-dialog-title" 
                    align='center'
                >
                    管理者ログイン
                </DialogTitle>
                <IconButton
                    aria-label="close-admin-login-dialog"
                    onClick={handleCloseAdmin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent 
                    dividers 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2,
                        backgroundImage: 'linear-gradient(to right, #abcfce, #d7b27a)',
                    }}
                >
                    <StyledTextField
                        label="メールアドレス"
                        variant="outlined"
                        value={adminEmail}
                        onChange={(e) => {
                            setAdminEmail(e.target.value);
                            setAdminEmailError("");
                        }}
                        required
                        error={Boolean(adminEmailError)} 
                        helperText={adminEmailError}
                        disabled={adminFormDisabled}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                        }}
                    />
                    <StyledTextField
                        label="パスワード"
                        type={adminValues.showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={adminValues.password}
                        onChange={(e) => {
                            setAdminValues({ ...adminValues, password : e.target.value });
                            setAdminPasswordError("");
                        }}
                        required
                        error={Boolean(adminPasswordError)} 
                        helperText={adminPasswordError}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        label="password icon"
                                        onClick={handleClickShowAdminPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {adminValues.showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        disabled={adminFormDisabled}
                    />
                    <Button
                        onClick={handleClicktoUser}
                        sx={{
                            width: '35%',
                            alignSelf: 'flex-end',
                            textDecoration: 'underline',
                            '&:hover': {
                                transform: 'scale(1.025)',
                            }
                        }}
                    >
                        受講者の方はこちら
                    </Button>
                    {adminRemainingAttempts > 0 && (
                        <Typography variant="body2" align="center">残りのログイン試行回数: {adminRemainingAttempts} 回 / 5回</Typography>
                    )}
                    {adminFormDisabled && (
                        <Typography variant="body2">{adminDisabledMessage}</Typography>
                    )}
                    <Button 
                        variant="contained" 
                        onClick={handleLoginAdmin}
                        disabled={adminFormDisabled}
                        sx={{ 
                            width: '50%',
                            color: '#c1c1a4',
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            borderRadius: '16px',
                            border: '2px solid #c1c1a4',
                            fontSize: '15px',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: 'white',
                            }
                        }}
                    >
                        ログイン
                    </Button>
                </DialogContent>
            </LoginDialog>
            <LoginDialog
                open={openUser}
                aria-labelledby="user-login-dialog"
            >
                <DialogTitle 
                    sx={{ m: 0, p: 2, backgroundImage: "linear-gradient(to right, #abcfce, #d7b27a)", color: 'white'}} 
                    id="user-login-dialog-title" 
                    align='center'
                >
                    受講者ログイン
                </DialogTitle>
                <IconButton
                    aria-label="close-user-login-dialog"
                    onClick={handleCloseUser}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <StyledTextField
                        label="メールアドレス"
                        variant="outlined"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError("");
                        }}
                        required
                        error={Boolean(emailError)} 
                        helperText={emailError}
                        disabled={formDisabled}
                    />
                    <StyledTextField
                        label="パスワード"
                        type={values.showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={values.password}
                        onChange={(e) => {
                            setValues({ ...values, password : e.target.value });
                            setPasswordError("");
                        }}
                        required
                        error={Boolean(passwordError)} 
                        helperText={passwordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        label="password icon"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        disabled={formDisabled}
                    />
                    <Button
                        onClick={handleClicktoAdmin}
                        sx={{
                            width: '35%',
                            alignSelf: 'flex-end',
                            textDecoration: 'underline',
                            '&:hover': {
                                transform: 'scale(1.025)',
                            }
                        }}
                    >
                        管理者の方はこちら
                    </Button>
                    {remainingAttempts > 0 && (
                        <Typography variant="body2" align="center">残りのログイン試行回数: {remainingAttempts} 回 / 5回</Typography>
                    )}
                    {formDisabled && (
                        <Typography variant="body2">{disabledMessage}</Typography>
                    )}
                    <Button 
                        variant="contained" 
                        onClick={handleLoginUser}
                        disabled={formDisabled}
                        sx={{ 
                            width: '50%',
                            color: '#c1c1a4',
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            borderRadius: '16px',
                            border: '2px solid #c1c1a4',
                            fontSize: '15px',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: 'white',
                            }
                        }}
                    >
                        ログイン
                    </Button>
                </DialogContent>
            </LoginDialog>
        </Grid>
    );
}