import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { TableFooter } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function LoginLogoutList() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();

    useEffect(() =>{
        const token = localStorage.getItem('token');
        if (!token) {
            console.log('No token')
            return;
        }
        fetch(`/api/user/login-logout`, 
            {
                method: 'get',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            }
        )
        .then(res => {
            if (res.status === 401) {
                alert('セッションが切れました。');
                navigate('/');
            } else if (res.status === 500) {
                navigate('/500');
            } else if (res.status === 404) {
                navigate('/404');
            }
            return res.json()
        })
        .then(res => {
            console.log(res.message);
            setData(res.data)
        })
        .catch(() => alert("Can't get data from db properly."));
    },[navigate])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + date.getUTCDate()).slice(-2);
        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    
        return `${year}/${month}/${day} ${hours}:${minutes}`;
    };

    return(
        <Box sx={{ width: '100%', maxWidth: 1000, bgcolor: 'background.paper' }}>
            <Typography sx={{ mt: 4, mb: 2,  mr: 4, ml: 4, fontWeight: 'bold' }} variant="h4" component="div">
                ログイン/ログアウト履歴
            </Typography>
            <TableContainer component={Paper} variant="outlined" sx={{ mr: 4, ml: 4, borderRadius: '20px' }}>
                <Table sx={{ minWidth: 300, [`& .${tableCellClasses.root}`]: {borderLeft: "none", borderRight: "none"} }} aria-label="simple table" >
                    <TableHead sx={{backgroundColor: '#E5E4E2'}}>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>ログイン日時</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>ログアウト日時</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { data? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">{row.loginTime !== null ? formatDate(row.loginTime) : "-"}</TableCell>
                                <TableCell align="center">{row.logoutTime !== null ? formatDate(row.logoutTime) : "-"}</TableCell>
                            </TableRow>
                        )) : null }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage="表示件数:"
                                rowsPerPageOptions={[
                                    { label: '5件', value: 5 },
                                    { label: '10件', value: 10 },
                                    { label: '50件', value: 50 },
                                    { label: '全て', value: data? data.length : 0 }
                                ]}
                                count={ data? data.length : 0 }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}